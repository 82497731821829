<template>
<v-container>
    <div class="ma-12">
        <v-data-table show-expand :headers="headers" :items="pedidos" sort-by="created_at" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Ventas</v-toolbar-title>
                </v-toolbar>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }">
                {{item.created_at.slice(0, 10)}}
            </template>
            <!-- Actualización -->
            <template v-slot:[`item.total`]="{ item }">
                {{item.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de ventas aún
            </template>
            <!-- Detalle -->
            <template  v-slot:expanded-item="{ headers, item }">
                <td class="pa-6" :colspan="headers.length">
                    <v-data-table
                  :headers="headerArray"
                  :items="item.platos"
                  hide-default-footer
                  item-key="name"
                  class="elevation-1"
                >


                <template v-slot:[`item.total`]="{ item }">
                  {{item.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
              </template>

              <template v-slot:[`item.producto`]="{ item }">
                  {{nombreProducto(item.producto)}}
              </template>


                </v-data-table>
                </td>
            </template>
            <!-- Monto -->
            <template v-slot:[`item.amount`]="{ item }">
                {{item.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </template>
        </v-data-table>
    </div>
</v-container>
</template>

<script>
  import axios from "axios";
  export default {
    props:{
      search:String
    },
    data () {
      return {
        headerArray:[
          { text: 'Cantidad', value: 'cantidad',},
          { text: 'Producto', value: 'producto',},
          { text: 'Subtotal', value: 'total',},
          { text: 'Comentario', value: 'comentario',},
        ]
      }
    },
    created(){
      this.$store.dispatch('pedido/getPedidos')
      this.$store.dispatch('producto/getProductos') 
      this.$store.dispatch('currentUser/getUser')
    },
    methods:{
      update(pedido, status){
        pedido.status = status
        axios.put("https://torstdio.site/api/v1/pedido/actualizar",Object.assign(pedido)).then(response=>{
            this.$store.dispatch('pedido/getPedidos')
        })
      },
      nombreProducto(id){
        return this.products.filter(producto=>producto.id == id).map(producto=>producto.nombre)[0]
      },
    },
    computed:{
      headers(){ 
        //this.quotations = this.quotationsLists
        return [
        { text: '', value: 'data-table-expand', sortable: false  },
        { text: 'id', value: 'id',},
        { text: 'Cliente', value: 'nombre',},
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Dirección', value: 'entrega' },
        { text: 'Estatus', value: 'status',},
        { text: 'Metodo', value: 'metodo' },
        { text: 'Total', value: 'total' },
        { text: 'Fecha', value: 'created_at' },
      ]},
      currentUser:{
        get(){
          return this.$store.state.currentUser.user;
        }
      },
      pedidos:{
        get(){
          var pedidos = this.$store.state.pedido.pedidos.filter(pedido=>pedido.sucursal == this.currentUser.sucursal)
          if(this.search!=''){
            pedidos = pedidos.filter(pedido =>
              (pedido.entrega+'guero').toLowerCase().includes(this.search+'guero'.toLowerCase()) ||
              (pedido.telefono+'guero').toLowerCase().includes((this.search+'guero').toLowerCase()) ||
              (pedido.nombre+'guero').toLowerCase().includes((this.search+'guero').toLowerCase())
            )
          }else{
            return pedidos
          }
        }
      },
      products(){
        return this.$store.state.producto.productos
      },
    },
}
</script>

<style>

</style>

<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none!important;
        background: #f7f7f7;
    }
</style>